import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../components/ExternalLink';
import LeadParagraph from '../../components/LeadParagraph';
import InternalLink from '../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "รับสมัครงาน",
      "style": {
        "position": "relative"
      }
    }}>{`รับสมัครงาน`}<a parentName="h1" {...{
        "href": "#%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3%E0%B8%87%E0%B8%B2%E0%B8%99",
        "aria-label": "รับสมัครงาน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
    บริษัทกำลังขยายงานเราจึงเปิดรับสมัครงานตำแหน่งงานดังนี้
    </LeadParagraph>
    <h3 {...{
      "id": "โปรแกรมเมอร์-2-ตำแหน่ง",
      "style": {
        "position": "relative"
      }
    }}>{`โปรแกรมเมอร์ 2 ตำแหน่ง`}<a parentName="h3" {...{
        "href": "#%E0%B9%82%E0%B8%9B%E0%B8%A3%E0%B9%81%E0%B8%81%E0%B8%A3%E0%B8%A1%E0%B9%80%E0%B8%A1%E0%B8%AD%E0%B8%A3%E0%B9%8C-2-%E0%B8%95%E0%B8%B3%E0%B9%81%E0%B8%AB%E0%B8%99%E0%B9%88%E0%B8%87",
        "aria-label": "โปรแกรมเมอร์ 2 ตำแหน่ง permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`มีใจรักในการพัฒนาซอฟแวร์ ชอบในการพัฒนาตัวเอง ชอบสร้างสรรงาน และชอบการสร้าง Opensoure
ไม่จำกัดเพศ หรือวัย สถานศึกษา ระดับการศึกษา ถ้าเป็นเด็กนักเรียนอายุควรเกิน 12 ปี ขึ้นไป(ผู้ปกครองต้องอนุญาต)
แต่อย่างน้อยควรมีภาษาใหม่ๆ อย่าง NodeJS,Rust,Go,C# ควรมี github repo opensource งานของตัวเองอย่างน้อย 1 งานที่เป็นงานของตัวเองจริงๆจะได้รับการพิจารณาร่วมทีมครับ รายได้ขึ้นกับความสามารถ มีโอกาสได้รับปันผลเป็นหุ้นของบริษัทในอนาคต`}</p>
    <p><strong parentName="p">{`หน้าที่ รับผิดชอบพัฒนาแพลตฟอร์มอีคอมเมิร์ซของบริษัท ซึ่งเป็น .net core framework ทำงานแบบรีโมทไม่ต้องเข้าออฟฟิศ`}</strong></p>
    <p><strong parentName="p">{`ส่งข้อมูลส่วนตัว และเอกสารไปที่อีเมล์บริษัท ถ้าบริษัทสนใจจะติดต่อกลับภายใน 3 วันครับ`}</strong></p>
    <h3 {...{
      "id": "พาร์ทเนอร์และฝ่ายขาย-2-ตำแหน่ง",
      "style": {
        "position": "relative"
      }
    }}>{`พาร์ทเนอร์และฝ่ายขาย 2 ตำแหน่ง`}<a parentName="h3" {...{
        "href": "#%E0%B8%9E%E0%B8%B2%E0%B8%A3%E0%B9%8C%E0%B8%97%E0%B9%80%E0%B8%99%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%9D%E0%B9%88%E0%B8%B2%E0%B8%A2%E0%B8%82%E0%B8%B2%E0%B8%A2-2-%E0%B8%95%E0%B8%B3%E0%B9%81%E0%B8%AB%E0%B8%99%E0%B9%88%E0%B8%87",
        "aria-label": "พาร์ทเนอร์และฝ่ายขาย 2 ตำแหน่ง permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`มีประสบการณ์ในงานขายสินค้ากลุ่มซอฟแวร์ จะได้รับการพิจารณาเป็นพิเศษ`}</p>
    <p><strong parentName="p">{`หน้าที่ รับผิดชอบการขายสินค้าและบริษัทรายได้จากส่วนแบ่งคอมมิสชั่นไม่ต่ำกว่า 10-30 %`}</strong></p>
    <p><strong parentName="p">{`ส่งข้อมูลส่วนตัว และเอกสารไปที่อีเมล์บริษัท ถ้าบริษัทสนใจจะติดต่อกลับภายใน 3 วันครับ`}</strong></p>
    <h3 {...{
      "id": "ติดต่อ",
      "style": {
        "position": "relative"
      }
    }}>{`ติดต่อ`}<a parentName="h3" {...{
        "href": "#%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B9%88%E0%B8%AD",
        "aria-label": "ติดต่อ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><a parentName="p" {...{
        "href": "mailto:anndream.com@gmail.com"
      }}>{`anndream.com@gmail.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      